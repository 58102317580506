import React, { useRef, useMemo, useEffect, useState } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import * as THREE from "three";

const ImagePlane = ({
  x = 0,
  y = 0,
  z = 0,
  rotation = [0, 0, 0],
  imageUrl,
  scaleX = 1,
  scaleY = 1,
  oscillate = false,
  borderColor = "#000000",
  borderThickness = 0.05,
  showBorder = false,
  onClick = () => {},  // <-- Añadido
}) => {
  const meshRef = useRef();
  const [aspect, setAspect] = useState(1);

  const texture = useLoader(THREE.TextureLoader, imageUrl);

  useEffect(() => {
    if (texture.image) {
      const { width, height } = texture.image;
      setAspect(width / height);
    }
  }, [texture]);

  const oscillationParams = useMemo(() => ({
    amplitude: Math.random() * 0.3 + 0.1,
    speed: Math.random() * 0.5 + 0.2,
    phase: Math.random() * Math.PI * 2,
  }), []);

  useFrame((state) => {
    if (oscillate && meshRef.current) {
      const t = state.clock.getElapsedTime();
      meshRef.current.position.y =
        y + oscillationParams.amplitude * Math.sin(t * oscillationParams.speed + oscillationParams.phase);
    }
  });

  const adjustedWidth = scaleX * aspect;
  const adjustedHeight = scaleY;

  return (
    <group
      ref={meshRef}
      position={[x, y, z]}
      rotation={rotation}
    >
      {showBorder && (
        <mesh
          position={[0, 0, -0.01]}
          scale={[adjustedWidth + borderThickness, adjustedHeight + borderThickness, 1]}
        >
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial color={borderColor} side={THREE.DoubleSide} />
        </mesh>
      )}

      <mesh scale={[adjustedWidth, adjustedHeight, 1]} onClick={onClick}>
        <planeGeometry args={[1, 1]} />
        <meshBasicMaterial
          map={texture}
          transparent
          side={THREE.DoubleSide}
        />
      </mesh>
    </group>
  );
};

export default ImagePlane;
