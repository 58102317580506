import React, { useState, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import FloatingMenu from "./FloatingMenu";
import NavigationBar from "./NavigationBar";
import Scene from "./Scene";
import InfoCard from "./InfoCard";
import TransformControlsPanel from "./TransformControlsPanel"; // Nuevo import
import InfoContent1 from "./InfoContent1";
import InfoContent2 from "./InfoContent2";
import * as THREE from "three";
import useStore from "./store";
import FloatingButton from "./FloatingButton"; // Importa el nuevo botón flotante
import UnderConstructionPopup from "./UnderConstructionPopup";

const App = () => {
  const targetRef = useRef();
  const cubes = useStore((state) => state.cubes);
  const [showPopup, setShowPopup] = useState(true);
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedPath, setSelectedPath] = useState("");
  const [transformMode, setTransformMode] = useState(null); // "translate" or 'rotate'
  const [showMenu, setShowMenu] = useState(false);
  const [showNavigationBar, setShowNavigationBar] = useState(true);
  const [infoCardVisible, setInfoCardVisible] = useState(false);
  const [transformPanelVisible, setTransformPanelVisible] = useState(false); // Nuevo estado
  const [infoContent, setInfoContent] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(null);
  const videoRef = useRef(null);
  const targetPosition = useRef(new THREE.Vector3()); // Correctamente definido
  const targetLookAt = useRef(new THREE.Vector3()); // Correctamente definido
  const orbitControlsRef = useRef();
  const [selectedObjectTransform, setSelectedObjectTransform] = useState({
    position: new THREE.Vector3(),
    rotation: new THREE.Euler(),
  });

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleDownloadJSON = () => {
    const jsonContent = JSON.stringify(cubes, null, 2);
    const blob = new Blob([jsonContent], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "updatedCubes.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleObjectClick = (object) => {
    if (object.path !== "" && object.path !== null) {
      setSelectedPath(object.path);
      setShowMenu(true);
      setShowNavigationBar(false);

      setSelectedObject(object);
      setSelectedObjectTransform({
        position: new THREE.Vector3(...object.position),
        rotation: new THREE.Euler(
          ...object.rotation.map(THREE.MathUtils.degToRad)
        ),
      });
    }
  };

  const handleMoveToggle = () => {
    setTransformMode("translate");
    setTransformPanelVisible(true); // Mostrar el panel de transformaciones
  };

  const handleRotateToggle = () => {
    setTransformMode("rotate");
    setTransformPanelVisible(true); // Mostrar el panel de transformaciones
  };

  const handleDetailsClick = () => {
    setShowMenu(false);
    setInfoCardVisible(true);
    console.log("====================================0");
    console.log(selectedObject);
    console.log(selectedObject.path);
    console.log("+++++");
    switch ("case1") {
      case "case1":
        setInfoContent(<InfoContent1 />);
        break;
      case "case2":
        setInfoContent(<InfoContent2 />);
        break;
      default:
        setInfoContent(<InfoContent1 />);
        break;
    }
  };

  const handleCloseCard = () => {
    setInfoCardVisible(false);
    setShowNavigationBar(true);
  };

  const handleClose = () => {
    setSelectedPath("");
    setTransformMode(null);
    setShowMenu(false);
    setShowNavigationBar(true);
    setTransformPanelVisible(false); // Cerrar el panel de transformaciones
  };

  const handleVideoPlayPause = () => {
    if (videoRef.current) {
      const isPlaying = !videoRef.current.paused;
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsVideoPlaying(!isPlaying);
    }
  };

  const handleNavigate = (viewportId) => {
    const viewport = cubes.find((vp) => vp.id_vp === viewportId);
    if (viewport) {
      const pos = viewport.position;
      const vec = viewport.viewportVec;

      targetPosition.current.set(
        pos[0] + vec[0],
        pos[1] + vec[1],
        pos[2] + vec[2]
      );
      targetLookAt.current.set(...pos);

      const camera = orbitControlsRef.current.object;
      camera.position.copy(targetPosition.current);
      camera.lookAt(targetLookAt.current);

      if (orbitControlsRef.current) {
        orbitControlsRef.current.target.copy(targetLookAt.current);
        orbitControlsRef.current.update();
      }

      console.log(
        "Navigated to viewport:",
        viewportId,
        "Position:",
        targetPosition.current,
        "LookAt:",
        targetLookAt.current
      );
    }
  };

  const handleTransformChange = (newPosition, newRotation) => {
    if (selectedObject) {
      const updatedRotation = newRotation.map(THREE.MathUtils.degToRad);

      setSelectedObjectTransform({
        position: new THREE.Vector3(...newPosition),
        rotation: new THREE.Euler(...updatedRotation),
      });

      if (targetRef.current) {
        targetRef.current.position.set(
          selectedObjectTransform.position.x,
          selectedObjectTransform.position.y,
          selectedObjectTransform.position.z
        ); // posición inicial
      }

      // Actualizar el estado global (usando Zustand)
      if (selectedObject.type === "firstLevel") {
        useStore
          .getState()
          .updateCubePosition(selectedObject.id_vp, newPosition);
        useStore
          .getState()
          .updateCubeRotation(selectedObject.id_vp, updatedRotation);
      } else {
        useStore
          .getState()
          .updateElementPosition(
            selectedObject.id_vp,
            selectedObject.id,
            newPosition
          );
        useStore
          .getState()
          .updateElementRotation(
            selectedObject.id_vp,
            selectedObject.id,
            updatedRotation
          );
      }
    }
  };

  return (
    <>
      {/*
       */}

      <Canvas style={{ height: "100vh", backgroundColor: "black" }}>
        <Scene
          viewports={cubes}
          selectedPath={selectedPath}
          transformMode={transformMode}
          targetPosition={targetPosition} // Pasando targetPosition
          targetLookAt={targetLookAt} // Pasando targetLookAt
          selectedObjectTransform={selectedObjectTransform}
          onClick={handleObjectClick}
          orbitControlsRef={orbitControlsRef}
          transformPanelVisible={transformPanelVisible}
          selectedObject={selectedObject}
        />
        <mesh ref={targetRef}>
          <boxGeometry args={[0.1, 0.1, 0.1]} />
          <meshBasicMaterial transparent opacity={0} />
        </mesh>

        {transformPanelVisible && selectedObject && (
          <TransformControlsPanel
            object={selectedObjectTransform}
            target={targetRef.current} // Objeto de referencia
            onTransformChange={handleTransformChange}
            mode={transformMode} // 👈 nuevo prop
            handleClose={handleClose}
          />
        )}
      </Canvas>

      {showMenu && (
        <FloatingMenu
          isVideoPlaying={isVideoPlaying}
          handleMoveToggle={handleMoveToggle}
          handleRotateToggle={handleRotateToggle}
          handleDetailsClick={handleDetailsClick}
          handleVideoPlayPause={handleVideoPlayPause}
          handleClose={handleClose}
        />
      )}

      {showNavigationBar && (
        <NavigationBar viewports={cubes} onNavigate={handleNavigate} />
      )}

      {infoCardVisible && (
        <InfoCard handleCloseCard={handleCloseCard} infoContent={infoContent} />
      )}

      <FloatingButton onDownload={handleDownloadJSON} />

      {showPopup && <UnderConstructionPopup handleClose={handleClosePopup} />}
    </>
  );
};

export default App;
