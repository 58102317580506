// TransformControlsPanel.js
import { TransformControls } from "@react-three/drei";
import { useEffect, useRef } from "react";
import * as THREE from "three";

const TransformControlsPanel = ({
  object,
  target, // Objeto 3D a controlar
  onTransformChange,
  mode = "translate" // Por defecto mover
}) => {
  const controlRef = useRef();

  useEffect(() => {
    const control = controlRef.current;
    if (!control || !target) return;
    // 🔄 Sincronizar posición y rotación del objeto con el target
    target.position.copy(object.position);
    target.rotation.copy(object.rotation);
    
    control.attach(target);

    const handleChange = () => {
      const pos = target.position;
      const rot = target.rotation;
      onTransformChange(
        [pos.x, pos.y, pos.z],
        [
          THREE.MathUtils.radToDeg(rot.x),
          THREE.MathUtils.radToDeg(rot.y),
          THREE.MathUtils.radToDeg(rot.z),
        ]
      );
    };

    control.addEventListener("objectChange", handleChange);

    return () => {
      if (control) {
        control.removeEventListener("objectChange", handleChange);
      }
    };
  }, [target, onTransformChange]);

  return <TransformControls ref={controlRef} mode={mode} />;
};

export default TransformControlsPanel;
