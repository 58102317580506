import { create } from "zustand";
import initialCubes from "../dahaleez_v3.config.json";

const useStore = create((set) => ({
  cubes: initialCubes,

  // Define las funciones de actualización aquí
  updateCubePosition: (id_vp, newPosition) =>
    set((state) => ({
      cubes: state.cubes.map((cube) =>
        cube.id_vp === id_vp ? { ...cube, position: newPosition } : cube
      ),
    })),

  updateCubeRotation: (id_vp, newRotation) =>
    set((state) => ({
      cubes: state.cubes.map((cube) =>
        cube.id_vp === id_vp ? { ...cube, rotation: newRotation } : cube
      ),
    })),

  updateElementPosition: (id_vp, elementId, newPosition) =>
    set((state) => ({
      cubes: state.cubes.map((cube) =>
        cube.id_vp === id_vp
          ? {
              ...cube,
              elements: cube.elements.map((element) =>
                element.id === elementId
                  ? { ...element, position: newPosition }
                  : element
              ),
            }
          : cube
      ),
    })),

  updateElementRotation: (id_vp, elementId, newRotation) =>
    set((state) => ({
      cubes: state.cubes.map((cube) =>
        cube.id_vp === id_vp
          ? {
              ...cube,
              elements: cube.elements.map((element) =>
                element.id === elementId
                  ? { ...element, rotation: newRotation }
                  : element
              ),
            }
          : cube
      ),
    })),
}));

export default useStore;
