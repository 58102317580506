// GradientBackground.js
import React from "react";
import * as THREE from "three";

const GradientBackground = ({ colors }) => {
  const uniforms = {
    color1: { value: new THREE.Color(colors[0]) }, // Esquina inferior izquierda
    color2: { value: new THREE.Color(colors[1]) }, // Esquina inferior derecha
    color3: { value: new THREE.Color(colors[2]) }, // Esquina superior derecha
    color4: { value: new THREE.Color(colors[3]) }, // Esquina superior izquierda
  };

  return (
    <mesh>
      {/* Usamos una esfera grande que envuelve la escena */}
      <sphereGeometry args={[500, 64, 64]} />
      <shaderMaterial
        uniforms={uniforms}
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        side={THREE.BackSide} // Importante para renderizar el interior de la esfera
        depthWrite={false}
      />
    </mesh>
  );
};

const vertexShader = `
varying vec3 vPosition;
void main() {
  vPosition = position;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
}
`;

const fragmentShader = `
uniform vec3 color1;
uniform vec3 color2;
uniform vec3 color3;
uniform vec3 color4;
varying vec3 vPosition;
const float PI = 3.1415926535897932384626433832795;

void main() {
  vec3 normalizedPosition = normalize(vPosition);
  float u = 0.5 + atan(normalizedPosition.z, normalizedPosition.x) / (2.0 * PI);
  float v = 0.5 - asin(normalizedPosition.y) / PI;

  // Ajuste de 'u' para crear simetría y eliminar la línea
  float adjustedU = abs(1.0 - 2.0 * u); // Rango de 0 a 1 y de vuelta a 0

  vec3 bottomColor = mix(color1, color2, adjustedU);
  vec3 topColor = mix(color4, color3, adjustedU);
  vec3 finalColor = mix(bottomColor, topColor, v);

  // Efecto de emisión para más brillo
  vec3 emission = finalColor * 1.5;  // Ajusta este valor para más brillo
  gl_FragColor = vec4(emission, 1.0);
}
`;

export default GradientBackground;
