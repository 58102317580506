import React, { useRef, useEffect, useMemo, Suspense } from "react";
import { Text, Image, Plane } from "@react-three/drei";
import * as THREE from "three";
import Oval from "./Oval";
import ImagePlane from "./ImagePlane";
import AudioComponent from "./AudioComponent"; // Import the audio component

import CubicCurvedLineBetweenPoints from "./CubicCurvedLineBetweenPoints";

const SecondLevelNodes = ({
  elementId,
  elements,
  parentId,
  parentPosition,
  parentRotation,
  //videoTextures,
  onClick,
}) => {
  const element = elements.find((obj) => obj.id === elementId);

  const handleElementClick = (event, id) => {
    event.stopPropagation();
    onClick({
      ...element,
      id_vp: parentId,
      path: parentId + "/" + id,
      videoElement: null,
      type: "secondLevel",
    });
  };

  const localPosition = new THREE.Vector3(
    element.position[0],
    element.position[1],
    element.position[2]
  );

  const parentRotEuler = new THREE.Euler(
    parentRotation[0],
    parentRotation[1],
    parentRotation[2]
  );

  const parentQuaternion = new THREE.Quaternion().setFromEuler(parentRotEuler);
  localPosition.applyQuaternion(parentQuaternion); // aplica rotación del padre

  const finalPosition = [
    parentPosition[0] + localPosition.x,
    parentPosition[1] + localPosition.y,
    parentPosition[2] + localPosition.z,
  ];

  const finalRotation = [
    parentRotation[0] + element.rotation[0],
    parentRotation[1] + element.rotation[1],
    parentRotation[2] + element.rotation[2],
  ];

  switch (element.type) {
    case "text":
      return (
        <>
          <Text
            key={element.id}
            position={finalPosition}
            rotation={finalRotation}
            fontSize={element.fontSize || 0.1}
            color={element.color || "white"}
            onClick={(e) => handleElementClick(e, element.id)}
          >
            {element.content}
          </Text>
        </>
      );

    case "audio":
      return (
        <>
          <Suspense fallback={null}>
            <AudioComponent
              autoplay={element.autoPlay ? element.autoPlay : true}
              position={element.position}
              rotation={finalRotation}
              filePath={element.filePath}
              subtitleFile={element.subtitleFile}
            />
          </Suspense>
        </>
      );
    case "oval":
      <>
        <Oval
          x={finalPosition[0]}
          y={finalPosition[1]}
          z={finalPosition[2]}
          imageUrl={element.url}
          onClick={(e) => handleElementClick(e, element.id)}
        />
      </>;

    case "img":
      return (
        <>
          <ImagePlane
            x={finalPosition[0]}
            y={finalPosition[1]}
            z={finalPosition[2]}
            rotation={finalRotation}
            imageUrl={element.url}
            scaleX={element.scale[0]}
            scaleY={element.scale[1]}
            oscillate={false}
            onClick={(e) => handleElementClick(e, element.id)}
          />

          {element.leaves &&
            element.leaves.map((l, index) => {
              const posIniLocal = new THREE.Vector3(...l.pos_ini);
              const posFinLocal = new THREE.Vector3(...l.pos_fin);

              // Aplica rotación del padre a los puntos locales
              posIniLocal.applyQuaternion(parentQuaternion);
              posFinLocal.applyQuaternion(parentQuaternion);

              // Suma la posición del padre
              const posIni = [
                parentPosition[0] + posIniLocal.x,
                parentPosition[1] + posIniLocal.y,
                parentPosition[2] + posIniLocal.z,
              ];
              const posFin = [
                parentPosition[0] + posFinLocal.x,
                parentPosition[1] + posFinLocal.y,
                parentPosition[2] + posFinLocal.z,
              ];

              return (
                <CubicCurvedLineBetweenPoints
                  key={`line-${element.id}-${index}`}
                  posIni={posIni}
                  posFin={posFin}
                  color="orange"
                />
              );
            })}
        </>
      );

    default:
      return (
        <mesh
          key={element.id}
          position={finalPosition}
          //rotation={finalRotation}
          //scale={element.scale || [0.5, 0.5, 0.5]}
          //onClick={(e) => handleElementClick(e, null, element.id)}
        >
          <boxGeometry args={[0.5, 0.5, 0.5]} />
          <meshStandardMaterial color={element.color || "gray"} />
        </mesh>
      );
  }
};

export default SecondLevelNodes;
