import React, { useMemo, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { Line } from "@react-three/drei";
import * as THREE from "three";

const CubicCurvedLineBetweenPoints = ({
  posIni = [0, 0, 0],
  posFin = [0, 5, 0],
  color = "red",
  controlOffset = -0.3,    // Vertical offset for the control points.
  segments = 10,           // Number of segments for the curve.
  lineWidth = 4,           // Desired line width in pixels.
  animationDuration = 2,   // Animation duration (in seconds) for drawing the line.
  pulseInterval = 2500,    // Pulse interval in milliseconds.
  pulseDuration = 300       // Duration of each pulse in milliseconds.
}) => {
  // Compute the cubic Bézier curve points.
  const points = useMemo(() => {
    const start = new THREE.Vector3(...posIni);
    const end = new THREE.Vector3(...posFin);
    const mid = new THREE.Vector3().addVectors(start, end).multiplyScalar(0.5);
    // Control point 1: halfway between start and mid, then offset vertically.
    const cp1 = start.clone().lerp(mid, 0.5).add(new THREE.Vector3(0, controlOffset, 0));
    // Control point 2: halfway between end and mid, then offset vertically.
    const cp2 = end.clone().lerp(mid, 0.5).add(new THREE.Vector3(0, controlOffset, 0));
    const curve = new THREE.CubicBezierCurve3(start, cp1, cp2, end);
    return curve.getPoints(segments);
  }, [posIni, posFin, controlOffset, segments]);

  // Animate the drawing by gradually increasing the number of displayed points.
  const [progress, setProgress] = useState(0);
  const startTimeRef = useRef(null);
  const lineRef = useRef();

  useFrame((state) => {
    // Gradual draw animation.
    if (!startTimeRef.current) startTimeRef.current = state.clock.getElapsedTime();
    const elapsed = state.clock.getElapsedTime() - startTimeRef.current;
    const t = Math.min(elapsed / animationDuration, 1);
    setProgress(t);

    // Animate the dash offset at a slower speed.
    if (lineRef.current && lineRef.current.material) {
      // Slower dash offset movement:
      lineRef.current.material.dashOffset = -state.clock.getElapsedTime() * 0.03;
    }

    // Pulse animation: calculate current time in ms modulo pulseInterval.
    const currentTimeMs = state.clock.getElapsedTime() * 1000;
    const modTime = currentTimeMs % pulseInterval;
    let pulseFactor = 0;
    if (modTime < pulseDuration) {
      pulseFactor = 0.005 - (modTime / pulseDuration /100);
    }

    // Adjust dashSize: baseDashSize plus an extra amount during the pulse.
    const baseDashSize = 0.1;      // Normal dash size.
    const additionalPulseSize = 0.01; // Extra dash size during the pulse.
    if (lineRef.current && lineRef.current.material) {
      lineRef.current.material.dashSize = baseDashSize + pulseFactor * additionalPulseSize;
    }
  });

  // Compute the subset of points to display based on draw progress.
  const displayedPoints = useMemo(() => {
    const count = Math.floor(points.length * progress);
    return count > 0 ? points.slice(0, count) : [points[0]];
  }, [points, progress]);

  return (
    <Line
      ref={lineRef}
      points={displayedPoints}
      color={color}
      lineWidth={lineWidth}
      dashed={true}
      dashScale={1}
      dashSize={0.1} // Will be overridden by the animation.
      gapSize={0.02}
    />
  );
};

export default CubicCurvedLineBetweenPoints;
