import React, { useRef, useEffect, useMemo, Suspense } from "react";
import { useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import FirstLevelNode from "./FirstLevelNode";
import GradientBackground from "./GradientBackground";
import * as THREE from "three";
import Oval from "./Oval";
// import Oval from "./Oval"; // Import the extracted Oval component
import AudioComponent from "./AudioComponent"; // Import the audio component
const Scene = ({
  viewports,
  selectedPath,
  transformMode,
  targetPosition, // Recibiendo targetPosition
  targetLookAt, // Recibiendo targetLookAt
  selectedObjectTransform,
  onClick,
  orbitControlsRef,
  transformPanelVisible,
  selectedObject,
}) => {
  const { camera } = useThree();
  const selectedMeshRef = useRef(null);

  const videoTextures = useMemo(() => {
    const textures = {};
    viewports.forEach((viewport) => {
      viewport.elements.forEach((element) => {
        if (element.type === "video") {
          const video = document.createElement("video");
          video.src = element.url;
          video.crossOrigin = "anonymous";
          video.loop = true;
          video.muted = true;
          video.playsInline = true;
          video.autoplay = element.autoplay;
          const texture = new THREE.VideoTexture(video);
          texture.minFilter = THREE.LinearFilter;
          texture.magFilter = THREE.LinearFilter;
          texture.format = THREE.RGBAFormat;
          textures[element.url] = texture;
        }
      });
    });
    return textures;
  }, [viewports]);

  useEffect(() => {
    if (selectedMeshRef.current) {
      selectedMeshRef.current.position.copy(selectedObjectTransform.position);
      selectedMeshRef.current.rotation.copy(selectedObjectTransform.rotation);
    }
  }, [selectedObjectTransform]);

  // Define ovals with distinct z positions to avoid overlapping.
  // Some ovals have bloom enabled; one of them uses imageOnly to render only the image.
  const ovals = [
    {
      x: -2,
      y: 0,
      z: 1,
      imageUrl: "nodes/4/4-1/oval-r4-3.png",
      color: "#ff6700",
      radiusX: 0.4,
      radiusY: 0.6,
      bloom: true,
    },
    {
      x: -1,
      y: 0,
      z: 1.2,
      imageUrl: "nodes/4/4-1/oval-r4-5.png",
      color: "#008080",
      radiusX: 0.3,
      radiusY: 0.5,
      bloom: false,
    },
    {
      x: -0,
      y: 1,
      z: 0.8,
      imageUrl: "nodes/4/4-1/oval-r4-6.png",
      color: "#fecf02",
      radiusX: 0.5,
      radiusY: 0.7,
      bloom: true,
    },
    {
      x: -1,
      y: 1,
      z: 1.4,
      imageUrl: "nodes/4/4-1/oval-r4-7.png",
      color: "#ff1493",
      radiusX: 0.4,
      radiusY: 0.6,
      bloom: false,
      imageOnly: true,
    },
    {
      x: -2,
      y: 1.5,
      z: 0.6,
      imageUrl: "nodes/4/4-1/oval-r4-9.png",
      color: "#aaaaaa",
      radiusX: 0.35,
      radiusY: 0.5,
      bloom: false,
    },
  ];

  return (
    <>
      <GradientBackground
        colors={[
          "#243D87", // Esquina inferior izquierda
          "#624393", // Esquina inferior derecha
          "#6BC6E1", // Esquina superior derecha
          "#E53C8D", // Esquina superior izquierda
        ]}
      />
      {/* Reduce ambient light if necessary so that the glow stands out */}
      <ambientLight intensity={0.3} />
      <pointLight position={[10, 10, 10]} />
      {viewports.map((viewport) => (
        <FirstLevelNode
          key={viewport.id_vp}
          viewport={viewport}
          //videoTextures={videoTextures}
          onClick={onClick}
          selectedPath={selectedPath}
          selectedMeshRef={
            selectedPath === viewport.id_vp ? selectedMeshRef : null
          }
        />
      ))}
     
      <Suspense fallback={null}>          
          <AudioComponent position={[-5, 1, -5]} filePath="/nodes/recordings/audio-1.ogg" subtitleFile="/nodes/recordings/audio-1-aws.srt"  />
           <AudioComponent position={[5, 1, 5]} filePath="/nodes/recordings/audio-2.ogg" subtitleFile="/nodes/recordings/audio-2-aws.srt"/>
           {/* <AudioComponent position={[-3, 1, 3]} filePath="/nodes/recordings/sound3.ogg" subtitleFile="/nodes/recordings/sound3-aws.srt"/>
            <AudioComponent position={[3, 1, 3]} filePath="/nodes/recordings/sound4.ogg" subtitleFile="/nodes/recordings/sound4-aws.srt"/>
            */}
      </Suspense> 
      {/* Render the animated ovals
      {ovals.map((props, i) => (
        <Oval key={i} {...props} />
      ))} */}
      <OrbitControls
        ref={orbitControlsRef}
        enabled={!transformPanelVisible || !selectedObject}
      />
    </>
  );
};

export default Scene;
