import React, { useRef, useState, useEffect } from "react";
import { PositionalAudio, Html } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

const AudioComponent = ({
  position,
  filePath,
  subtitleFile, // Path to your _en.srt file
  distance = 10,
  loop = true,
  autoplay = true,
  volume = 1.0,
}) => {
  const audioRef = useRef();
  const [subtitles, setSubtitles] = useState([]);
  const [currentSubtitle, setCurrentSubtitle] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [accumulatedTime, setAccumulatedTime] = useState(0);
  const [audioStatus, setAudioStatus] = useState("paused"); // "playing", "paused", "finished"

  // Convert time string "hh:mm:ss,ms" to seconds.
  const timeStringToSeconds = (timeString) => {
    const parts = timeString.split(/[:,]/);
    return (
      parseInt(parts[0], 10) * 3600 +
      parseInt(parts[1], 10) * 60 +
      parseInt(parts[2], 10) +
      parseInt(parts[3], 10) / 1000
    );
  };

  // Parse SRT text into subtitle entries.
  const parseSRT = (srtText) => {
    const entries = [];
    const regex = /(\d+)\s+(\d{2}:\d{2}:\d{2},\d{3})\s*-->\s*(\d{2}:\d{2}:\d{2},\d{3})\s+([\s\S]*?)(?=\n\n|\n*$)/g;
    let match;
    while ((match = regex.exec(srtText)) !== null) {
      entries.push({
        index: parseInt(match[1], 10),
        start: timeStringToSeconds(match[2]),
        end: timeStringToSeconds(match[3]),
        text: match[4].trim().replace(/\n/g, " "),
      });
    }
    return entries;
  };

  // Fetch and parse subtitles.
  useEffect(() => {
    if (subtitleFile) {
      fetch(subtitleFile)
        .then((res) => res.text())
        .then((text) => {
          setSubtitles(parseSRT(text));
          console.log("Parsed subtitles:", parseSRT(text));
        })
        .catch((err) => console.error("Error loading subtitles:", err));
    }
  }, [subtitleFile]);

  // Record initial audio start time.
  useEffect(() => {
    if (audioRef.current && autoplay) {
      const audio = audioRef.current;
      setTimeout(() => {
        setStartTime(audio.context.currentTime);
        setAudioStatus("playing");
      }, 100);
    }
  }, [autoplay]);

  // Toggle pause/resume on sphere click.
  const handlePauseToggle = () => {
    if (audioRef.current) {
      const audio = audioRef.current;
      if (audio.isPlaying) {
        // When pausing, add the played time to accumulatedTime.
        const played = audio.context.currentTime - startTime;
        setAccumulatedTime((prev) => prev + played);
        audio.pause();
        setAudioStatus("paused");
      } else {
        audio.play();
        // When resuming, set startTime such that played time continues.
        setStartTime(audio.context.currentTime - accumulatedTime);
        setAudioStatus("playing");
      }
    }
  };

  // Update subtitles and audio status each frame.
  useFrame(() => {
    if (startTime !== null && audioRef.current) {
      // Compute the effective playback time.
      const playTime = audioRef.current.isPlaying
        ? audioRef.current.context.currentTime - startTime + accumulatedTime
        : accumulatedTime;

      // Update audio status for non-looping audio.
      if (!loop && audioRef.current.buffer && playTime >= audioRef.current.buffer.duration) {
        setAudioStatus("finished");
      } else if (audioRef.current.isPlaying) {
        setAudioStatus("playing");
      } else {
        setAudioStatus("paused");
      }

      // Update current subtitle based on playTime.
      let current = "";
      for (let i = 0; i < subtitles.length; i++) {
        const entry = subtitles[i];
        if (playTime >= entry.start && playTime <= entry.end) {
          current = entry.text;
          break;
        }
      }
      setCurrentSubtitle(current);
      // For debugging: console.log("Play time:", playTime, "Subtitle:", current);
    }
  });

  // Choose sphere color based on audioStatus.
  const sphereColor =
    audioStatus === "playing" ? "green" : audioStatus === "finished" ? "gray" : "red";

  return (
    <group position={position}>
      <PositionalAudio
        ref={audioRef}
        url={filePath}
        distance={distance}
        loop={loop}
        autoplay={autoplay}
        volume={volume}
      />
      {/* Html overlay for subtitles with an offset so it's not hidden */}
      <Html
        center
        position={[0, 0, 0.5]}
        style={{
          pointerEvents: "none",
          fontSize: "32px",
          color: "white",
          background: "rgba(0, 0, 0, 0.6)",
          padding: "4px 8px",
          borderRadius: "4px",
          textShadow: "2px 2px 4px rgba(0,0,0,0.8)",
          whiteSpace: "nowrap",
        }}
      >
        <div>{currentSubtitle || " "}</div>
      </Html>
      {/* Clickable sphere to toggle pause/resume */}
      <mesh onClick={handlePauseToggle}>
        <sphereGeometry args={[0.2, 32, 32]} />
        <meshStandardMaterial color={sphereColor} />
      </mesh>
    </group>
  );
};

export default AudioComponent;
